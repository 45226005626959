// @flow
import * as React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "next-i18next";
import { handleToUrl } from "@/utils/sys";

type Props = {};
const Index = (props: Props) => {
    const { t, i18n } = useTranslation("home");

    const data = [
        {
            name: "influencers",
            svg: (
                <svg
                    className="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="4643"
                >
                    <path
                        d="M498.871795 416.662974c241.690256 0 437.61559 122.748718 437.61559 274.195693S740.562051 965.054359 498.871795 965.054359c-241.690256 0-437.61559-122.748718-437.61559-274.195692S257.181538 416.662974 498.871795 416.662974z m0-355.406769a155.569231 155.569231 0 1 1 0 311.138462 155.569231 155.569231 0 0 1 0-311.138462z"
                        p-id="4644"
                        fill="#ffffff"
                    ></path>
                </svg>
            ),
            bg: "linear-gradient(180deg, #FF6F6F 0%, #F73838 100%)",
            shadow: " 0px 6px 10px 0px rgba(252,93,93,0.3300)",
            url: "/mobile/influencers/sales",
            suburl: "Breakout",
        },
        {
            name: "goods",
            svg: (
                <svg
                    className="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="4976"
                >
                    <path
                        d="M512 0c127.552 0 231.317333 101.76 234.581333 228.522667L746.666667 234.666667v21.333333h42.666666a170.666667 170.666667 0 0 1 170.666667 170.666667v426.666666a170.666667 170.666667 0 0 1-170.666667 170.666667H234.666667a170.666667 170.666667 0 0 1-170.666667-170.666667V426.666667a170.666667 170.666667 0 0 1 170.666667-170.666667h42.666666v-21.333333C277.333333 107.114667 379.093333 3.349333 505.856 0.085333L512 0z m146.922667 558.144a42.666667 42.666667 0 0 0-56.064 22.314667 98.474667 98.474667 0 0 1-180.053334 1.984l-1.493333-2.986667a42.666667 42.666667 0 0 0-76.117333 38.485333 183.786667 183.786667 0 0 0 336.021333-3.733333 42.666667 42.666667 0 0 0-22.293333-56.064zM512 85.333333a149.333333 149.333333 0 0 0-149.248 144.213334L362.666667 234.666667v21.333333h298.666666v-21.333333a149.333333 149.333333 0 0 0-144.213333-149.248L512 85.333333z"
                        p-id="4977"
                        fill="#ffffff"
                    ></path>
                </svg>
            ),
            bg: "linear-gradient(180deg, #75F1DA 0%, #14B49F 100%)",
            shadow: " 0px 6px 10px 0px rgba(101,231,208,0.4600)",
            url: "/mobile/products/sales ",
            suburl: "Products",
        },
        {
            name: "_video",
            svg: (
                <svg
                    className="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="5492"
                >
                    <path
                        d="M512 42.666667a469.333333 469.333333 0 1 0 0 938.666666 469.333333 469.333333 0 0 0 0-938.666666z m196.522667 500.48L441.856 719.189333a37.376 37.376 0 0 1-57.856-31.232V336.128a37.290667 37.290667 0 0 1 57.856-31.146667l266.666667 175.957334a37.290667 37.290667 0 0 1 0 62.293333z"
                        p-id="5493"
                        fill="#fff"
                    ></path>
                </svg>
            ),
            bg: "linear-gradient(180deg, #FFE56D 0%, #FF9555 100%)",
            shadow: " 0px 6px 10px 0px rgba(255,218,105,0.5300)",
            url: "/mobile/videos/products",
            suburl: "HotVideos",
        },
        {
            name: "content",
            svg: (
                <svg
                    className="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="5630"
                >
                    <path
                        d="M361.344 864l33.984-161.728h171.904l-33.92 161.728h125.568l34.048-161.728H838.4V583.36h-120l31.168-146.56H896V318.016h-120.96l33.088-157.952h-125.632l-33.024 157.952H477.44L510.592 160H385.92l-33.088 157.952H185.6v118.912h141.696l-31.168 146.496H128v118.912h142.656L236.608 864h124.736z m231.424-280.64H420.864l31.168-146.56h171.904l-31.168 146.56z"
                        p-id="5631"
                        fill="#fff"
                    ></path>
                </svg>
            ),
            bg: "linear-gradient(180deg, #9D84FF 0%, #6E5DFE 100%)",
            shadow: " 0px 6px 10px 0px rgba(144,121,255,0.5000)",
            url: "/mobile/content/songs?name=breakout",
            suburl: "Musics",
        },
    ];

    return (
        <div className={`${styles.tags}`}>
            <div className={`${styles.container}`}>
                {(data || []).map((item: any, index: any) => (
                    <div
                        key={index}
                        className={`${styles.item}`}
                        onClick={() => handleToUrl(item.url)}
                    >
                        <div
                            className={styles.up}
                            style={{
                                background: item.bg,
                                boxShadow: item.shadow,
                            }}
                        >
                            <div className={styles.svg}>{item.svg}</div>
                            <div
                                className={
                                    styles.hot +
                                    ` ${index !== 1 ? "!hidden" : ""}`
                                }
                            >
                                <span style={{ transform: "scale(0.6)" }}>
                                    HOT
                                </span>
                            </div>
                        </div>
                        <div className={styles.down}>{t(item.name)}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Index;
